import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import './signup.css';

export default function SignUpForm() {
    const [state, handleSubmit] = useForm('meqwryoe');

    if (state.succeeded) {
        return <p>Thanks for your submission!</p>;
    }
  
    return (
      <div className="mx-auto max-w-md px-6 py-12 bg-white border-0 shadow-lg sm:rounded-3xl">
        <h1 className="text-2xl font-bold mb-8">Sign up</h1>
        <form method="post" onSubmit={handleSubmit}>
            <div className="relative z-0 w-full mb-5">
                <input
                type="email"
                name="email"
                placeholder=" "
                className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                />
                <label htmlFor="email" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">Enter Email Address</label>
                <ValidationError 
                    prefix="Email" 
                    field="email"
                    errors={state.errors}
                />
            </div>
            <div className="relative z-0 w-full mb-5">
                <input
                type="text"
                name="zipcode"
                pattern="[0-9]{5}"
                placeholder=" "
                className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                />
                <label htmlFor="zipcode" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">Enter Zipcode</label>
            </div>
            <div className="relative z-0 w-full mb-5">
                <input
                    type="date"
                    name="duedate"
                    placeholder=" "
                    className="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
                />
                <label htmlFor="duedate" className="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">Due Date</label>
            </div>
            <button className="w-full px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none" type="submit" disabled={state.submitting}>Register</button>
        </form>
      </div>
    );
}