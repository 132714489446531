import SignUpForm from "./signup-form";

function App() {
  return (
    <div>
      <h1 className="text-3xl font-bold underline">
        Welcome, Sarah! Feel free to sign up...
      </h1>
      <br/>
      <SignUpForm />
    </div>
  );
}

export default App;
